.slideContainer .slide {
    background-color: transparent;
    display: flex;
    justify-content: center;
}
.slideProgressBarContainer .bar {
    width: 150px;
}
.slideHeaderContainer {
    margin-bottom: 40px;
}
.slideHeader {
    color: #FFFFFF;
    margin-right: 15px;
    font-size: 15px;
    letter-spacing: 3.6px;
    font-weight: 600;
    text-transform: uppercase;
    opacity: 0;
    white-space: nowrap;
}
.slideHeaderDivider {
    background-color: #404040;
    width: 0%;
    height: 1px;
}
.slideTitleContainer {
    height: 90px;
    width: 530px;
    margin-bottom: 20px;
    overflow: hidden;
    display: flex;
    align-items: flex-end;
}
.slideTitle {
    text-align: left;
    font-size: 38px;
    width: 530px;
    height: fit-content;
    max-height: 90px;
    letter-spacing: 0;
    font-weight: 700;
    color: #FFFFFF;
    opacity: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.slideLogoContainer {
    width: 100%;
    margin-bottom: 20px;
    opacity: 0;
}
.slideLogo {
    height: auto;
    width: 250px;
}
.slideDescription {
    text-align: left;
    font-size: 17px;
    width: 90%;
    font-weight: 400;
    line-height: 1.45;
    letter-spacing: 0;
    color: #FFFFFF;
    opacity: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    transform: translateY(-10%);
}
.slideUnpurchasedIcon {
    position: absolute;
    top: 17px;
    right: 22px;
    width: 20px;
    height: 29px;
    z-index: 1;
    background-color: #BFBFBF;
    -webkit-mask: url('../../../../assets/Lock Icon.svg') no-repeat center;
    mask: url('../../../../assets/Lock Icon.svg') no-repeat center;
    -webkit-mask-size: contain;
    mask-size: contain;
    transition: all .3s ease-in-out;
}
.slideInfoContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100px;
    opacity: 0;
    transform: translateY(-10%);
    position: relative;
    z-index: 1;
    gap: 5px;
}
.activeSlide {
    transition: all .25s ease-in;
    opacity: 1;
    transform: translateY(0);
}
.inactiveSlide {
    transition: all .25s ease-out;
    opacity: 0;
    transform: translateY(-10%);
}
.activeSlideDivider {
    transition: all .5s ease-in;
    width: 100%;
}
.inactiveSlideDivider {
    transition: all .5s ease-out;
    opacity: 0%;
}
.slideContent {
    height: 357px;
}
@media only screen and (max-width: 1289px) {
    .slideRight {
        padding-bottom: 0 !important;
        padding-top: 0 !important;
    }
    .slideContainer .slide {
        min-width: 100% !important;
        max-width: 100% !important;
    }
    .slideContainer {
        flex-direction: column-reverse !important;
        align-items: center !important;
        justify-content: center !important;
    }
    .slideContainer .slide.slideLeft {
        margin-top: 65px !important;
    }
    .slideContainer {
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
    }
    .slideContent {
        padding: 0 !important;
    }
    .slideHeaderContainer {
        margin-bottom: 40px !important;
    }
    .slideLogoContainer {
        margin-bottom: 20px !important;
    }
    .slideInfoContainer {
        margin-top: 15px !important;
    }
    .slideBtnContainer {
        margin-top: 0 !important;
    }
    .slideImgContainer {
        padding: 0 !important;
    }
    .slideUnpurchasedContainer {
        right: 0 !important;
        top: 0 !important;
        border-top: 9em solid #404040 !important;
        border-left: 9em solid transparent !important;
    }
    .slideUnpurchasedIcon {
        top: 1em !important;
        right: 2.25em !important;
        width: 1.25em !important;
        height: 3em !important;
    }
    .slideInfo {
        margin-top: 0px !important;
        /*margin-left: 15px !important;*/
    }
    .slideProgressBarWrapper {
        margin: 0 auto !important;
        width: 100% !important;
    }
    .slideProgressBarContainer {
        width: 100% !important;
    }
    .slideProgressBarContainer .bar {
        width: 200px !important;
    }
}
@media only screen and (max-width: 800px) {
    .slideContainer .slide {
        min-width: 100% !important;
        max-width: 100% !important;
    }
    .slideContainer {
        flex-direction: column-reverse !important;
        align-items: center !important;
        justify-content: center !important;
    }
    .slideContainer .slide.slideLeft {
        margin-top: 55px !important;
    }
    .slideContainer .slick-dots {
        right: 0 !important;
        top: 0 !important;
    }
    .slideHeaderContainer {
        margin-bottom: 25px !important;
    }
    .slideImgContainer {
        padding: 0 !important;
    }
    .slideContent {
        padding: 0 !important;
        width: 100% !important;
    }
    .slideLogoContainer {
        margin-bottom: 0 !important;
    }
    .slideTitleContainer {
        width: 100% !important;
        max-width: 530px !important;
    }
    .slideTitle {
        margin-bottom: 0 !important;
        width: auto !important;
        height: auto !important;
    }
    .slideDescription {
        display: none !important;
    }
    .slideInfoContainer {
        flex-direction: row !important;
        height: auto !important;
        margin-top: 0 !important;
    }
    .slideBtnContainer {
        margin-top: 10px !important;
    }
    .slideInfo {
        width: 100% !important;
        margin-top: 10px !important;
        margin-left: 0 !important;
    }
    .slideProgressBarContainer .bar {
        width: 50% !important;
    }
    .carouselLiveIndicatorContainer {
        top: 10px !important;
        left: 10px !important;
        padding: 14px 14px !important;
        background-color: black !important;
    }
    .carouselIndicator {
        width: 10px !important;
        height: 10px !important;
        margin-right: 10px !important;
    }
    .slideUnpurchasedContainer {
        right: 0 !important;
        border-top: 96px solid #404040 !important;
        border-left: 96px solid transparent !important;
    }
    .slideUnpurchasedIcon {
        top: 16px !important;
        right: 22px !important;
        width: 16px !important;
        height: 22px !important;
    }
    .slideContent {
        height: 245px;
    }
}
