.intermediateBtnContainer {
    flex-direction: row;
}
.paywallInfoWrapper {
    flex-direction: row;
    margin-top: 0;
}
.paywallInfoSingleWrapper {
    margin-top: 0;
}

@media (max-width: 875px) {
    .paywallInfoWrapper {
        margin-top: 10px;
    }
}
@media (max-width: 600px) {
    .paywallInfoWrapper {
        flex-direction: column;
        margin-top: 0;
    }
    .paywallInfoSingleWrapper {
        margin-top: 10px;
    }
    .paywallInfoDivider {
        display: none;
    }
}
.intermediateBackgroundOverlayCover {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: radial-gradient(farthest-side at 73% 21%, transparent, rgb(0 0 0));
    transition: all .3s ease-in-out;
}
.intermediateBackgroundOverlayTop {
    position: absolute;
    top: 0;
    width: 100%;
    height: 50%;
    background: linear-gradient(to top, transparent 50%, #0D0D0D 100%);
}
/*.intermediateBackgroundOverlayLeft {*/
/*    position: absolute;*/
/*    top: 0;*/
/*    width: 50%;*/
/*    height: 100%;*/
/*    background: linear-gradient(to left, transparent 0%, #0D0D0D 100%);*/
/*}*/
.intermediateBackgroundOverlayBottom {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 50%;
    background: linear-gradient(to bottom, transparent 20%, #0D0D0D 80%);
}
@media only screen and (max-width: 1289px) {
    .intermediateContainer {
        padding: 150px 90px 0px !important;
        align-items: flex-start !important;
        flex-direction: column-reverse !important;
    }
    .intermediateLeft {
        min-width: 100% !important;
        max-width: 100% !important;
    }
    .intermediateRight {
        min-width: 100% !important;
        max-width: 100% !important;
        padding: 0 !important;
        margin-bottom: 50px !important;
    }
}
@media only screen and (max-width: 800px) {
    .intermediateContainer {
        padding: 150px 36px 0px !important;
    }
    .intermediateBackgroundImg {
        height: 400px !important;
    }
    .intermediateLeft {
        min-width: 100% !important;
        max-width: 100% !important;
    }
    .intermediateHeader {
        font-size: 13px !important;
    }
    .intermediateTitle {
        font-size: 36px !important;
    }
    .intermediateSubtitle {
        font-size: 17px !important;
    }
    .intermediateDescription {
        font-size: 15px !important;
    }
    .intermediateLogoContainer {

    }
    .intermediateLogo {
        width: 185px !important;
    }
    .intermediateCircularBtnReplay {
        width: 39px !important;
        height: 39px !important;
        margin-right: 15px;
    }
    .intermediateCircularBtnAdd {
        width: 39px !important;
        height: 39px !important;
        margin-right: 15px;
    }
    .intermediateBtn {
        font-size: 15px !important;
    }
    .intermediateBtnBuy {
        width: 51px !important;
        height: 51px !important;
    }
    .intermediateBtnSubscribe {
        width: 124px !important;
        height: 39px !important;
    }
    .intermediateBtnResume {
        width: 117px !important;
        height: 39px !important;
    }
    .intermediateInfo {
        width: 275px !important;
    }
    .intermediateUpcomingText {
        font-size: 13px !important;
    }
    .intermediateProgressBarTopText {
        font-size: 13px !important;
    }
    .intermediateProgressBarText {
        font-size: 13px !important;
    }
    .intermediateDurationText {
        font-size: 13px !important;
    }
    .intermediateMetaName {
        font-size: 13px !important;
    }
    .intermediateMetaValue {
        font-size: 13px !important;
    }
    .intermediateRight {
        width: 50% !important;
    }
}
